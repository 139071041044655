import moment from "moment";

export const isLive = true;
export const isStaging = true;
const liveApiUrl = "https://api.scrapc.com";
const stagingUrl = "https://uatapi.scrapc.com";
const localApiUrl = "http://192.168.1.96:8000";

const appUrl = isLive
  ? isStaging
    ? "https://uatweb.scrapc.com"
    : "https://app.scrapc.com"
  : "http://localhost:3002";

const serverUrl = isLive
  ? isStaging
    ? stagingUrl
    : "https://api.scrapc.com"
  : "http://192.168.0.137:5000";

const apiUrl = isLive ? (isStaging ? stagingUrl : liveApiUrl) : localApiUrl;

const BaseSetting = {
  appUrl: appUrl,
  serverUrl: serverUrl,
  siteName: "ScrapC-Admin",
  siteIcon: "",
  copyRightText: `Copyright © ${moment().year()} by ScrapC. All Rights Reserved`,
  GOOGLE_CLIENT_ID: "",
  GOOGLE_TRANSLATE_API_KEY: "AIzaSyCDrjvDqO-7Zic2Ykhci3s_LILrj7eObqA",
  // ------------------------------Client Domain----------------------------------------
  // -----------------------------------------------------------------------------------
  //   domainUrl: isLive ? liveApiUrl : localApiUrl,
  tinymceEditorApiKey: "97akzk1226qf5c407eyvbh2ok2cegmd50h5pmbqsa9shgug1",
  GOOGLE_PIN_CODE_API_KEY: "AIzaSyCm46euRGUbbk-ZGzTVj2c9l12go9vc4KQ",
  apiUrl: apiUrl,
  revalidate: true,
  templateKey: "",
  mapKey: "",
  endpoint: {
    adminlogin: "/admin/login",
    createcategory: "/admin/create-category",

    findcondition: "/admin/find-condition",
    createcondition: "/admin/create-condition",
    updatecondition: "/admin/update-condition",
    deletecondition: "/admin/delete-condition",

    createplan: "/admin/create-plan",
    allplans: "/admin/all-plans",
    updateplan: "/admin/update-plan",
    deleteplan: "/admin/delete-plan",
    getOnePlan: "/admin/find-plan-by-id",
    changeStatusPlan: "/admin/change-plan-status",

    allCategory: "/admin/find-category",
    getOneCategory: "/admin/find-category-by-id",
    updateCategory: "/admin/update-category",
    deleteCategory: "/admin/delete-category",
    changeStatusCategory: "/admin/change-category-status",

    allDraftData: "/admin/all-in-review-posts",
    allBusinessData: "/admin/all-business-type",
    deletePost: "/admin/delete-post",

    parentCategory: "/admin/find-parent-category",
    createBusinessType: "/admin/create-business-type",
    updateBusinessType: "/admin/update-business-type",
    deleteBusinessType: "/admin/delete-business-type",
    changeStatusBusinessType: "/admin/change-status-business-type",

    activeUsers: "/admin/active-users",
    verifyUser: "/admin/verify-user",
    oneDraftData: "/admin/in-review-post-by-id",
    changeDraftPostStatus: "/admin/changePostStatus",

    allUsersList: "/admin/all-user",
    removeUser: "/admin/remove-user",
    createUser: "/admin/create-user",
    updateUser: "/admin/update-user",

    allIceBreakingQuestionsData: "/admin/all-icebreaking-question",
    createIceBreakingQuestion: "/admin/create-icebreaking-question",
    updateIceBreakingQuestion: "/admin/update-icebreaking-question",
    deleteIceBreakingQuestion: "/admin/delete-icebreaking-question",
    changeStatusIceBreakingQuestions:
      "/admin/change-status-icebreaking-question",

    countryList: "/user/get-country-list",
    languageList: "/user/get-language-list",

    reportUsersList: "/admin/all-report-users",

    systemParametersList: "/admin/all-system-parameter",
    systemParametersCreate: "/admin/create-system-parameter",
    systemParametersUpdate: "/admin/update-system-parameter",
    systemParametersDelete: "/admin/delete-system-parameter",

    allExpiredPosts: "/admin/all-expired-posts",
    oneExpiredPost: "/admin/find-expired-post-by-id",
    deleteMultiplePost: "/admin/delete-multiple-posts",
    deleteMultipleUsers: "/admin/delete-multiple-users",

    allVerifiedUsers: "/admin/pending-verify-users-list",
    verifyUserProfile: "/admin/verify-user-profile",
    getOneUserProfileData: "/admin/pending-verify-one-users",

    businessTypeList: "/admin/all-business-type",

    countriesList: "/admin/get-all-country-list",
    changeCountryStatus: "/admin/change-country-status",

    interestedPostList: "/admin/interested-post-list",
    interestedUserList: "/admin/interested-user-list",

    logisticsList: "/admin/logistic-list",

    contactUsList: "/admin/get-contact-us-list",
    updateContactUs: "/user/contact-us",
    deleteContactUs: "/admin/delete-contact-us",

    CMSList: "/admin/get-cms-list",
    createCMS: "/admin/create-cms",
    updateCMS: "/admin/update-cms",
    deleteCMS: "/admin/delete-cms-data",

    emailTemplateList: "/admin/get-list-email-template",
    createEmailTemplate: "/admin/create-email-template",
    updateEmailTemplate: "/admin/update-email-template",
    deleteEmailTemplate: "/admin/delete-email-template",
    changeEmailTemplateStatus: "/admin/change-email-tamplate-status",

    getOnePostData: "/admin/get-single-post-data",
    subCategoryList: "/admin/find-subcategory",
    subCategoryByParentIdList: "/admin/find-subcategory-by-parent-id",
    // businessTypeList: "/admin/all-business-type",
    conditionList: "/admin/find-condition",
    currencyList: "/user/get-active-currency-list",
    categoryList: "/admin/find-parent-category",
    createUpdateSellPost: "/post/add-or-update-sellpost",
    createUpdateBuyPost: "/post/add-or-update-buypost",
    removeFile: "/user/remove-file",

    notVerifiedUsersList: "/admin/not-verified-users",
    notVerifiedUsersNotification: "/admin/notVerified-user-notification",

    premiumUser: "/admin/premium-user",

    getUserData: "/admin/get-user-data",
    updateProfile: "/admin/update-profile",
    enableTwoFactor: "/admin/enable-twoFactor",
    verifyOTP: "/admin/verify-otp",

    isCompress: "/post/is-compress",

    //Sms Template
    smsTemplateList: "/admin/get-all-sms-templates",
    createSmsTemplate: "/admin/create-sms-template",
    updateSmsTemplate: "/admin/update-sms-template",
    deleteSmsTemplate: "/admin/delete-sms-template",
    changeSmsTemplateStatus: "/admin/change-sms-template-status",

    //Notification Template
    getNotificationTemplates: "/admin/get-all-notification-templates",
    changeNotificationStatus: "/admin/change-notification-template-status",
    createNotificationTemplate: "/admin/create-notification-template",
    updateNotificationTemplate: "/admin/update-notification-template",
    deleteNotificationTemplate: "/admin/delete-notification-template",
    //Rating Template
    getRatingList: "/admin/get-rating-list",

    //Alert All Data
    GetAllAlertData: "/admin/get-alert-list",

    //Bulk Email
    GetSubscribeEmails: "/admin/get-subscribe-email",
    SendBulkEmails: "/admin/send-bulk-emails",
    GetSendEmailList: "/admin/get-send-email_list",
    DeleteSentEmail: "/admin/delete-sent-email",
    SentEmail: "/admin/sent-news-letter-email",
    UpdateEmail: "/admin/update-sent-email",
    //Login History
    GetLoginhistory: "/admin/get-user-login-history",

    //Remove User Verification
    RemoveUserVerificaion: "/admin/remove-user-verification",

    //Login with User
    LoginWithUser: "/admin/login-as-user",

    // common data
    commonData: "/user/get-common-data",

    createNotification: "/admin/create-notification",
    getNotificationData: "/admin/get-notifications-list",

    createGroup: "/admin/create-group",
    updateGroup: "/admin/update-group",
    groupList: "/admin/get-group-list",
    deleteGroup: "/admin/delete-group",

    newsLetterList: "/admin/get-list-newsletter-subscribe",
    addToGroup: "/admin/add-to-group",
    getNewsLetterEmails: "/admin/get-news-letter-emails-by-type",
    getAllLanguageData: "/admin/get-all-languages",
    getLanguageDatabase: "/admin/get-language-datatable",
    updateTranslationValue: "/admin/update-transation-value",
    autoUpdateWithgoogle: "/admin/auto-update-with-google",
    changeLanguageStatus: "/admin/change-language-status",

    imageUpload: "/admin/upload-email-template-image",

    //image upload in expired edit post
    singlePostImageUpload: "/post/upload-post-image",
    changeMainImage: "/post/change-main-image",
    updatePost: "/post/update-post",

    //cutom Notification
    getAllCustomNotifications: "/admin/custom-notification-list",
    getCustomNotification: "admin/custom-notification-list",
    adminUserList: "/admin/user-list",
    adminPostlist: "/post/post-list",
    countryAndStateList: "/admin/country-state-list",
    CategoryAndSubcategoryList: "/user/get-common-data",
    sendPostNotification: "/admin/notification-by-post",
    sendUserNotification: "/admin/notification-by-user",
    businessTypeListData: "/admin/all-business-type",
    dashBoardApi: "/admin/admin-dashboard",
    boostPost: "/admin/boost-posts",
    getUserRating: "/user/get-user-ratings",
    getFeedBack: "/admin/get-feedback-list",

    removeBoostPost: "/admin/remove-boost-posts",
    notAddedProfileUsersData: "/admin/not-added-profile-data-user",
    sendNotificationToNotAddedProfileUsers:
      "/admin/pending-profile-notification",
    countryStateCityListByPincode: "/admin/get-country-state-city",
    getSEOList: "/admin/get-seo-details-list",
    updateSEOList: "/admin/update-seo-details",
    countriesList: "/admin/get-all-country-list",
    allUsersNew: "/admin/get-all-users",
  },
};

export default BaseSetting;
